.feature {
  height: 100%;
  border: 1px solid $white-offset;
  border-radius: 3px;
  padding: 20px;
  background-color: $white-offset;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .feature-image {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    display: flex;
    margin-bottom: 20px;
    text-align: center;
  }
  img {
    width: 100%;
    height: auto;
    opacity: 80%;
  }
  .feature-title {
  }
  .feature-content {
    margin-bottom: 0;
    margin-top: auto;
  }
}
