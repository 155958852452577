.button {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: $primary;
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: $white-dark;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  &:hover {
    color: $white-offset;
    background-color: lighten($primary, 10%);
    transform: translateY(-1px);
    //box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    text-decoration: none;
  }
}
