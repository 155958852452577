.main-menu {

    .dropbtn {
        font-size: 16px;
        border: none;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: $primary;
        min-width: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 10;
    }

    .dropdown-content a {
        color: $black;
        padding: 12px 12px;
        text-decoration: none;
        display: block;
        border: 2px solid $primary;
    }

    .dropdown-content a:hover {
        background-color: $white-offset;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
    > ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > li {
            list-style: none;
            font-size: 1rem;
            > a {
                padding: 10px 12px 10px 12px;
                display: inline-block;
                font-weight: normal;
                text-decoration: none;
                color: $primary;
                &:hover {
                    text-decoration: underline;
                }
            }
            &.active {
                > a {
                    font-weight: bold;
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                        transition: all 225ms ease-in 0s;
                    }
                }
            }
        }
    }
}
